import React, {useState} from 'react'
import {Button, Form} from 'react-bootstrap'
import * as Yup from 'yup'
import {Formik, Field, Form as FormikForm, ErrorMessage} from 'formik'

import {useCreatePipelineStageCard} from '../../../queries/pipelines'

const PipeLineCardFormLite = ({pipelineId, stageId}: {pipelineId: string; stageId: string}) => {
  const [showCardForm, setShowCardForm] = useState(false)
  const createCard = useCreatePipelineStageCard()

  return (
    <div>
      {!showCardForm && (
        <Button variant='transparent' className='p-0 w-100' onClick={() => setShowCardForm(true)}>
          <div className='NewCard-button justify-content-center border border-secondary rounded mt-4 d-flex flex-row align-items-center'>
            <i className='fa-solid fs-4 text-black fa-add'></i>
            <span className='mx-2 fs-4'>Add New Card</span>
          </div>
        </Button>
      )}

      {showCardForm && (
        <Formik
          initialValues={{name: ''}}
          validationSchema={Yup.object({
            name: Yup.string()
              .max(50, 'Name cannot exceed 50 characters')
              .required('Name is required'),
          })}
          onSubmit={async (values, {resetForm}) => {
            try {
              await createCard.mutateAsync({
                pipelineId: pipelineId,
                stageId: stageId,
                data: values,
              })
              resetForm()
            } catch (error) {
              console.error(error)
            }
          }}
        >
          {() => (
            <FormikForm>
              <Form.Group className='my-3' controlId='formGroupName'>
                <Field
                  as={Form.Control}
                  name='name'
                  type='text'
                  placeholder='Card Name....'
                  maxLength={50}
                />
                <ErrorMessage name='name' component='div' className='text-danger' />
              </Form.Group>
              <div className='d-end'>
                <Button
                  variant='secondary'
                  size='sm'
                  className='ms-1'
                  onClick={() => setShowCardForm(false)}
                  disabled={createCard.isPending}
                >
                  x
                </Button>
                <Button
                  variant='primary'
                  size='sm'
                  className='ms-1'
                  type='submit'
                  disabled={createCard.isPending}
                >
                  {createCard.isPending ? 'Adding...' : 'Add Card'}
                </Button>
              </div>
            </FormikForm>
          )}
        </Formik>
      )}
    </div>
  )
}

export default PipeLineCardFormLite
