import {useEffect, useState} from 'react'
import {DragDropContext, Droppable, Draggable} from 'react-beautiful-dnd'
import {useSearchParams} from 'react-router-dom'

import PipeLineStage from './PipeLineStage'
import {
  useGetPipelineById,
  useUpdatePipelineStageOrder,
  useUpdatePipelineStageCardOrder,
} from '../../../queries/pipelines'
import PipeLineStageForm from './PipeLineStageForm'
import FetchingSpinner from '../../../modules/common/FetchingSpinner'

function PipeLineStages() {
  const [searchParams] = useSearchParams()
  const pipelineId: any = searchParams.get('pipelineId')
  const [currentPipelineId, setCurrentPipelineId] = useState<any>(pipelineId)
  const [showAddStageModal, setShowAddStageModal] = useState(false)
  const [pipelineStages, setPipelineStages] = useState<any>([])
  const {data: singlePipelineData, isLoading} = useGetPipelineById(currentPipelineId)
  const pipelineStageOrderMutation = useUpdatePipelineStageOrder()
  const stageCardOrderMutation = useUpdatePipelineStageCardOrder()

  useEffect(() => {
    setCurrentPipelineId(pipelineId)
  }, [pipelineId])

  useEffect(() => {
    setPipelineStages(singlePipelineData?.pipeline?.pipelineStages || [])
  }, [singlePipelineData])

  const handleStageCardDragEnd = async (result: any) => {
    const {source, destination, draggableId} = result

    // Find the source and destination stages
    const sourceStageIndex = pipelineStages.findIndex(
      (stage: any) => stage.uuid === source.droppableId
    )
    const destinationStageIndex = pipelineStages.findIndex(
      (stage: any) => stage.uuid === destination.droppableId
    )

    // If source and destination are the same stage
    if (sourceStageIndex === destinationStageIndex) {
      const stageCards = Array.from(pipelineStages[sourceStageIndex].pipelineStageCards)

      // Remove card from the source index
      const [removedCard] = stageCards.splice(source.index, 1)

      // Add the card to the new position in the same stage
      stageCards.splice(destination.index, 0, removedCard)

      // Update the state with the new card order for this stage
      const updatedPipelineStages = [...pipelineStages]
      updatedPipelineStages[sourceStageIndex].pipelineStageCards = stageCards

      setPipelineStages(updatedPipelineStages)

      try {
        await stageCardOrderMutation.mutateAsync({
          pipelineId,
          stageId: source.droppableId,
          cardId: draggableId,
          order: destination.index + 1,
        })
      } catch {
        setPipelineStages(pipelineStages)
      }
    } else {
      // Cross-stage drag-and-drop handling
      const sourceStageCards = Array.from(pipelineStages[sourceStageIndex].pipelineStageCards)
      const destinationStageCards = Array.from(
        pipelineStages[destinationStageIndex].pipelineStageCards
      )

      // Remove card from the source stage
      const [removedCard] = sourceStageCards.splice(source.index, 1)

      // Add card to the destination stage
      destinationStageCards.splice(destination.index, 0, removedCard)

      // Update both source and destination stages' cards
      const updatedPipelineStages = [...pipelineStages]
      updatedPipelineStages[sourceStageIndex].pipelineStageCards = sourceStageCards
      updatedPipelineStages[destinationStageIndex].pipelineStageCards = destinationStageCards

      setPipelineStages(updatedPipelineStages)

      try {
        await stageCardOrderMutation.mutateAsync({
          pipelineId,
          stageId: source.droppableId, // Original stage
          cardId: draggableId,
          order: destination.index + 1,
          newStageId: destination.droppableId, // New stage if cross-stage drag
        })
      } catch {
        setPipelineStages(pipelineStages)
      }
    }
  }

  const handleStageDragEnd = async (result: any) => {
    const [oldStages, reorderedStages] = [Array.from(pipelineStages), Array.from(pipelineStages)]
    const [removed] = reorderedStages.splice(result.source.index, 1)
    reorderedStages.splice(result.destination.index, 0, removed)

    setPipelineStages(reorderedStages)
    try {
      await pipelineStageOrderMutation.mutateAsync({
        pipelineId,
        stageId: result.draggableId,
        order: result.destination.index + 1,
      })
    } catch {
      setPipelineStages(oldStages)
    }
  }

  const handleDragEnd = async (result: any) => {
    const {source, destination} = result

    // If there's no destination, exit early
    if (!destination) return

    // If the card is dropped in the same place, exit early
    if (source.droppableId === destination.droppableId && source.index === destination.index) {
      return
    }

    if (result.type === 'card') {
      return handleStageCardDragEnd(result)
    }

    return handleStageDragEnd(result)
  }

  if (isLoading) {
    return <FetchingSpinner />
  }

  return (
    <div className='pipeline-view-container overflow-x-scroll w-100'>
      <DragDropContext onDragEnd={handleDragEnd}>
        <Droppable droppableId='pipelineStages' direction='horizontal' type='stage'>
          {(provided) => (
            <div
              className='d-flex pipeline-view-content overflow-auto'
              ref={provided.innerRef}
              {...provided.droppableProps}
            >
              {pipelineStages.map((stage: any, columnIndex: number) => (
                <Draggable key={stage.uuid} draggableId={stage.uuid} index={columnIndex}>
                  {(provided) => (
                    <div
                      className='pipeline-column cards-moving-width p-2'
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                    >
                      <PipeLineStage stage={stage} pipelineId={pipelineId} />
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
              {singlePipelineData?.pipeline && (
                <div className='w-100 p-2'>
                  <div
                    className='d-flex flex-row rounded align-items-baseline px-4 py-3 cursor-pointer w300'
                    style={{
                      backgroundColor: 'rgb(244, 244, 244)',
                    }}
                    onClick={() => setShowAddStageModal(true)}
                  >
                    <i className='text-black fs-4 fw-bold fa-solid fa-plus'></i>
                    <h4 className='mx-2'>Add Stage</h4>
                  </div>
                </div>
              )}
              {!pipelineId && (
                <p className='fw-bold fs-4 text-center w-100'>Create a Pipeline to Get Started!</p>
              )}
            </div>
          )}
        </Droppable>
      </DragDropContext>
      {showAddStageModal && (
        <PipeLineStageForm
          pipelineId={pipelineId}
          showStageFormModal={showAddStageModal}
          setShowStageFormModal={setShowAddStageModal}
        />
      )}
    </div>
  )
}

export default PipeLineStages
