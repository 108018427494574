import React, {useState} from 'react'
import moment from 'moment'
import {Button} from 'react-bootstrap'

import {useDeletePipelineStageCardComment} from '../../../queries/pipelines'
import Image from '../../../modules/common/Image'
import Attachments from '../../feeds/components/FeedAttachments'
import {useAuth} from '../../../modules/auth'
import {ConfirmationModal} from '../../../modules/common/ConfirmationModal'
import PipelineCardCommentForm from './PipelineCardCommentForm'

interface PipelineCardCommentsRowProps {
  pipelineId: string
  stageId: string
  cardId: string
  comment: any
  isReply?: boolean
}

const PipelineCardCommentsRow: React.FC<PipelineCardCommentsRowProps> = ({
  pipelineId,
  stageId,
  cardId,
  comment,
  isReply = false,
}) => {
  const [editingIndex, setEditingIndex] = useState<any>(null)
  const [showConfirmationModal, setShowConfirmationModal] = useState(false)
  const [commentToDelete, setCommentToDelete] = useState<any>(null)
  const [replyOpenId, setReplyOpenId] = useState<string | null>(null) // Change to track comment ID
  const {currentUser} = useAuth()

  const deleteCommentMutation = useDeletePipelineStageCardComment()

  const editComment = (index: any) => {
    setEditingIndex(index)
  }

  const deleteComment = async () => {
    if (commentToDelete) {
      try {
        await deleteCommentMutation.mutateAsync({
          pipelineId: pipelineId,
          stageId: stageId,
          cardId: cardId,
          commentId: commentToDelete.id,
        })
        setCommentToDelete(null)
        setShowConfirmationModal(false)
      } catch {}
    }
  }

  const formatComment = (comment: string) => {
    const comments = comment?.split(' ')
    return comments.map((comment, index) => {
      if (comment.startsWith('http://') || comment.startsWith('https://')) {
        return (
          <a
            className='comments-link'
            key={index}
            href={comment}
            target='_blank'
            rel='noopener noreferrer'
          >
            {index === 0 ? comment : ` ${comment}`}
          </a>
        )
      } else {
        return <span key={index}>{index === 0 ? comment : ` ${comment}`}</span>
      }
    })
  }

  const toggleReplyField = (commentId: string) => {
    setReplyOpenId((prevId) => (prevId === commentId ? null : commentId))
  }

  return (
    <div
      style={{
        marginLeft: isReply ? '60px' : '0px',
      }}
      key={comment.id}
    >
      <div className='d-flex align-items-center'>
        <Image src={comment?.user?.absoluteProfilePath} />
        <div
          className={`outline-none ${
            comment.attachments?.length && !editingIndex
              ? 'align-items-start'
              : 'align-items-center'
          } ${
            !editingIndex && 'd-flex justify-content-between'
          } bg-light border-custom rounded w-100 mx-1 my-2 px-2 py-3`}
        >
          <div className={`${editingIndex === comment.id ? 'w-100' : 'w-80'}`}>
            <p className='m-0 fw-bold'>
              {comment.user?.firstName} {comment.user?.lastName}{' '}
              <span className='fw-normal text-muted'>{moment(comment?.createdAt).fromNow()}</span>
            </p>
            {editingIndex === comment.id ? (
              <PipelineCardCommentForm
                setEditingIndex={setEditingIndex}
                editedComment={comment}
                pipelineId={pipelineId}
                stageId={stageId}
                cardId={cardId}
              />
            ) : (
              <>
                <p className='m-0 w-80'>{formatComment(comment.comment)}</p>
                <div className='d-flex align-items-center overflow-x-scroll'>
                  <Attachments attachments={comment.attachments} hideDeleteIcon={false} />
                </div>
              </>
            )}
          </div>
          {currentUser?.id === comment.user.id && !editingIndex && (
            <div className='dropdown mt-2 me-2'>
              <button
                className='btn btn-transparent text-gray'
                type='button'
                data-bs-toggle='dropdown'
                aria-expanded='false'
              >
                <i className='fa-solid fs-2 feed-menu-icon fa-ellipsis'></i>
              </button>
              <ul className='dropdown-menu'>
                <li
                  className='dropdown-item cursor-pointer'
                  onClick={() => editComment(comment.id)}
                >
                  <i className='fa fa-pencil me-2'></i> Edit
                </li>
                <li
                  onClick={() => {
                    setShowConfirmationModal(true)
                    setCommentToDelete(comment)
                  }}
                  className='dropdown-item cursor-pointer'
                >
                  <i className='fa fa-trash me-2'></i> Delete
                </li>
              </ul>
            </div>
          )}
        </div>
      </div>
      <div className='d-end'>
        {!isReply && (
          <Button
            variant='outline-secondary'
            size='sm'
            className='text-gray'
            onClick={() => toggleReplyField(comment.id)}
          >
            Reply
          </Button>
        )}
      </div>
      {replyOpenId === comment.id && (
        <PipelineCardCommentForm
          pipelineId={pipelineId}
          stageId={stageId}
          cardId={cardId}
          parentComment={comment}
          toggleReplyField={() => toggleReplyField(comment.id)}
        />
      )}
      {showConfirmationModal && (
        <ConfirmationModal
          showModal={showConfirmationModal}
          onHideModal={() => {
            setShowConfirmationModal(false)
            setCommentToDelete(null)
          }}
          disableBtns={deleteCommentMutation?.isPending}
          onConfirm={deleteComment}
          onCancel={() => setShowConfirmationModal(false)}
        />
      )}
    </div>
  )
}

export default PipelineCardCommentsRow
