import {useState, KeyboardEvent, useEffect} from 'react'
import clsx from 'clsx'
import {Link, useNavigate, useSearchParams} from 'react-router-dom'
import {Button} from 'react-bootstrap'

import {useLayout} from '../../core'
import {Topbar} from './Topbar'
import {useAuth} from '../../../modules/auth'
import HeaderMessage from './HeaderMessage'
import {isProfileIncomplete} from '../../../modules/common/utils'
import HeaderNotification from './HeaderNotification'
import {KTIcon} from '../../../../_metronic/helpers'

import './HeaderWrapper.css'

export function HeaderWrapper() {
  const {classes, attributes, config} = useLayout()
  const {aside} = config
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const q = searchParams.get('q') || ''
  const {currentUser, selectedCommunity} = useAuth()
  const [search, setSearch] = useState(q)
  const btnClass = 'btn btn-icon btn-active-light-primary w-30px h-30px w-md-40px h-md-40px'
  const isProfileIncompleted = isProfileIncomplete(currentUser)

  const handleKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      // If Enter key is pressed, perform search
      onSearch()
    }
  }

  const onSearch = () => {
    search && navigate(`/search?q=${search}`)
  }

  useEffect(() => {
    setSearch(q)
  }, [q])

  return (
    <div
      id='kt_header'
      className={clsx(
        'header',
        classes.header.join(' '),
        'align-items-stretch',
        'header-wrapper',
        'bg-light'
      )}
      {...attributes.headerMenu}
    >
      {/* begin::Brand */}
      <div className='header-brand'>
        {/* begin::Logo */}
        <Link to='/'>
          <div className='d-flex'>
            <i className='fab fs-2 fa-accusoft'></i>
            <h5 style={{fontSize: '17px'}} className='logo-text mx-3 d-flex align-items-center'>
              {selectedCommunity?.name}
              <span className='badge bg-danger text-light fw-bold mx-1'>Beta</span>
            </h5>
          </div>
        </Link>
        {/* end::Logo */}

        {aside.minimize && (
          <div
            id='kt_aside_toggle'
            className='btn btn-icon w-auto px-0 btn-active-color-primary aside-minimize'
            data-kt-toggle='true'
            data-kt-toggle-state='active'
            data-kt-toggle-target='body'
            data-kt-toggle-name='aside-minimize'
          >
            <KTIcon iconName='exit-left' className='fs-1 me-n1 minimize-default' />
            <KTIcon iconName='entrance-left' className='fs-1 minimize-active' />
          </div>
        )}

        {/* begin::Aside toggle */}
        <div className='d-flex align-items-center d-lg-none ms-n3 me-1' title='Show aside menu'>
          <div
            className='btn btn-icon btn-active-color-primary w-30px h-30px'
            id='kt_aside_mobile_toggle'
          >
            <KTIcon iconName='abstract-14' className='fs-1' />
          </div>
        </div>
        {/* end::Aside toggle */}
      </div>
      <div className='d-none lg-header d-md-flex w-100 mx-5 align-items-center'>
        <div className='d-flex justify-content-center w-75'>
          <div
            className='d-flex justify-content-center align-items-center rounded bg-white w-100'
            style={{maxWidth: '700px'}}
          >
            <i
              className={`fa-solid fa-magnifying-glass ml10 ${
                isProfileIncompleted && 'incomplete-profile'
              }`}
            ></i>
            <input
              type='text'
              className={`form-control border-0 bg-transparent ${
                isProfileIncompleted && 'incomplete-profile'
              }`}
              placeholder='Search'
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              onKeyDown={handleKeyDown}
              disabled={isProfileIncompleted}
            />
            <Button variant='primary' disabled={isProfileIncompleted} onClick={onSearch}>
              Search
            </Button>
          </div>
        </div>
        <div className={clsx('d-flex align-items-center')}>
          <div>
            <Link
              className={`nav-link fs-4 ${isProfileIncompleted && 'incomplete-profile'}`}
              to='/feeds/public'
            >
              <i style={{color: '#D0D2DA'}} className={`fa-solid fa-home fs-1 ${btnClass}`}></i>
            </Link>
          </div>
          <div className={` ${isProfileIncompleted && 'incomplete-profile'}`}>
            <HeaderMessage btnClass={btnClass} />
          </div>
          <div>
            <HeaderNotification />
          </div>
          <Topbar />
        </div>
      </div>
    </div>
  )
}
