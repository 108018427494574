import React, {useState} from 'react'
import {Button, Form, Modal} from 'react-bootstrap'
import {Formik, Field, Form as FormikForm, ErrorMessage} from 'formik'
import * as Yup from 'yup'
import Select from 'react-select'
import DatePicker from 'react-datepicker'

import {useUpdatePipelineStageCard} from '../../../queries/pipelines'
import EditorIndex from '../../../modules/editor/Editor'
import Attachments from '../../feeds/components/FeedAttachments'

import 'react-datepicker/dist/react-datepicker.css'

const validationSchema = Yup.object({
  name: Yup.string().required('Name is required'),
  description: Yup.string().optional(),
  cover: Yup.object().optional(),
  startDate: Yup.date().nullable().optional(),
  dueDate: Yup.date()
    .nullable()
    .optional()
    .min(Yup.ref('startDate'), 'Due date cannot be before start date'),
})

function PipeLineCardForm({
  pipelineId,
  stageId,
  stagesCardValues,
  showCardModal,
  setShowCardModal,
  setShowPipeLineDetailModal,
}: {
  pipelineId: string
  stageId: string
  stagesCardValues?: any
  showCardModal: boolean
  setShowCardModal: (show: boolean) => void
  setShowPipeLineDetailModal?: any
}) {
  const [isFileUploading, setIsFileUploading] = useState<boolean>(false)
  const updateCard = useUpdatePipelineStageCard()

  const handleCloseCardModal = () => {
    setShowCardModal(false)
    if (setShowPipeLineDetailModal) setShowPipeLineDetailModal(false)
  }

  const colorOptions = [
    {value: '#dc3545', label: 'Red'},
    {value: '#0d6efd', label: 'Blue'},
    {value: '#198754', label: 'Green'},
    {value: '#ffc107', label: 'Yellow'},
    {value: '#6610f2', label: 'Purple'},
    {value: '#f8f9fa', label: 'Light Gray'},
    {value: '#6c757d', label: 'Gray'},
    {value: '#212529', label: 'Black'},
  ]

  return (
    <Modal show={showCardModal} onHide={handleCloseCardModal} centered size='lg' backdrop='static'>
      <Modal.Header closeButton>
        <Modal.Title>Update Card</Modal.Title>
      </Modal.Header>
      <Formik
        initialValues={{
          name: stagesCardValues?.name || '',
          description: stagesCardValues?.description || '',
          startDate: stagesCardValues?.startDate,
          dueDate: stagesCardValues?.dueDate,
          labels: stagesCardValues?.labels || [],
          cover: stagesCardValues?.cover || undefined,
          status: stagesCardValues?.status || 'inProgress',
          attachments: stagesCardValues?.attachments || [],
        }}
        validationSchema={validationSchema}
        onSubmit={async (values, {resetForm}) => {
          try {
            const payload: any = {
              name: values.name,
              startDate: values.startDate,
              dueDate: values.dueDate,
              description: values.description,
              labels: values.labels,
              status: values.status,
              cover: values.cover,
              attachments: values.attachments,
            }

            await updateCard.mutateAsync({
              pipelineId: pipelineId,
              stageId: stageId,
              cardId: stagesCardValues?.uuid,
              data: payload,
            })
            handleCloseCardModal()
            resetForm()
          } catch (error) {
            console.error(error)
          }
        }}
      >
        {({setFieldValue, values, handleChange}) => (
          <FormikForm>
            <Modal.Body>
              <Form.Group className='mb-3' controlId='formGroupName'>
                <label htmlFor='name' className='fw-bold fs-6'>
                  Name:
                </label>
                <Field
                  as={Form.Control}
                  name='name'
                  type='text'
                  placeholder='Card Name....'
                  maxLength={50}
                />
                <ErrorMessage name='name' component='div' className='text-danger' />
              </Form.Group>
              <div className='row'>
                <div className='col-6 my-3'>
                  <label htmlFor='startDate' className='fw-bold fs-6'>
                    Start Date:
                  </label>
                  <DatePicker
                    className='form-control'
                    selected={values.startDate}
                    placeholderText='Select start date'
                    onChange={(date) => setFieldValue('startDate', date)}
                    dateFormat='yyyy-MM-dd'
                  />
                </div>

                <div className='col-6 my-3'>
                  <label htmlFor='dueDate' className='fw-bold fs-6'>
                    Due Date:
                  </label>
                  <DatePicker
                    className='form-control'
                    selected={values.dueDate}
                    placeholderText='Select due date'
                    onChange={(date) => setFieldValue('dueDate', date)}
                    minDate={values.startDate}
                    dateFormat='yyyy-MM-dd'
                  />
                </div>
              </div>
              <div className='form-check mt-3 mb-5'>
                <input
                  className='form-check-input'
                  type='checkbox'
                  name='status'
                  id='status'
                  checked={values.status === 'completed'}
                  onChange={(e) =>
                    setFieldValue('status', e.target.checked ? 'completed' : 'inProgress')
                  }
                />
                <label className='fw-bold fs-6' htmlFor={`status`}>
                  Completed
                </label>
              </div>
              <div>
                <label htmlFor='labels' className='fw-bold fs-6'>
                  Select labels:
                </label>
                {values.labels?.length > 0 && (
                  <div className='d-flex mt-2 mb-3'>
                    {values.labels.map((label: any, index: number) => (
                      <div
                        key={index}
                        className='w-25 rounded mx-1'
                        style={{height: '18px', backgroundColor: label.value}}
                      ></div>
                    ))}
                  </div>
                )}
                <Select
                  options={colorOptions}
                  closeMenuOnSelect={false}
                  className='card-labels'
                  name='labels'
                  isMulti
                  value={values.labels}
                  onChange={(selectedOptions) => {
                    setFieldValue('labels', selectedOptions)
                  }}
                />
              </div>
              <div className='mt-5'>
                <label htmlFor='description' className='fw-bold fs-6'>
                  Description:
                </label>
                <EditorIndex
                  value={values.description}
                  name='description'
                  handleChange={handleChange}
                  initialValue={stagesCardValues?.description}
                  resourceType='pipelines'
                  isUploading={isFileUploading}
                  setIsUploading={setIsFileUploading}
                  onFileUpload={(result) =>
                    setFieldValue('attachments', [...values.attachments, result])
                  }
                />
                <ErrorMessage name='description' component='div' className='text-danger' />
              </div>
              <div className='mt-3'>
                <Attachments
                  attachments={values.attachments || stagesCardValues?.attachments}
                  hideDeleteIcon={true}
                  handleDeleteFile={(index: number) => {
                    const updatedAttachments = [...values.attachments]
                    updatedAttachments.splice(index, 1)
                    setFieldValue('attachments', updatedAttachments)
                  }}
                  isUploading={isFileUploading}
                />
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant='secondary'
                onClick={handleCloseCardModal}
                disabled={updateCard.isPending || isFileUploading}
              >
                Close
              </Button>
              <Button
                variant='primary'
                type='submit'
                disabled={updateCard.isPending || isFileUploading}
              >
                {updateCard.isPending ? 'Updating...' : 'Submit'}
              </Button>
            </Modal.Footer>
          </FormikForm>
        )}
      </Formik>
    </Modal>
  )
}

export default PipeLineCardForm
