import {useState, useEffect} from 'react'
import {useNavigate, useSearchParams} from 'react-router-dom'
import {Spinner} from 'react-bootstrap'

import CustomDropdown from '../../../modules/common/CustomDropDown'
import {useGetAllPipelines, useDeletePipeline} from '../../../queries/pipelines'
import {ConfirmationModal} from '../../../modules/common/ConfirmationModal'

const UserPipelines = ({
  setShowPipeLineModal,
  setPipelineValues,
  setShowUserPipeLineModal,
}: {
  setShowPipeLineModal: (show: boolean) => void
  setPipelineValues: (values: any) => void
  setShowUserPipeLineModal?: (show: boolean) => void
}) => {
  const [searchParams] = useSearchParams()
  const navigate = useNavigate()
  const pipelineId = searchParams.get('pipelineId')
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [pipelineToDelete, setPipelineToDelete] = useState<any>(null)
  const {data: pipelines, isLoading} = useGetAllPipelines()
  const {mutateAsync: deletePipeline} = useDeletePipeline()

  useEffect(() => {
    if (!pipelineId && pipelines?.length) {
      navigate(`?pipelineId=${pipelines[0].uuid}`)
    }
  }, [pipelineId, pipelines])

  const handleDeletePipeLine = async () => {
    try {
      if (pipelineToDelete) {
        deletePipeline(pipelineToDelete.uuid)
      }
    } catch (error) {
      console.error(error)
    } finally {
      setShowDeleteModal(false)
      setPipelineToDelete(null)
      navigate('/pipeline')
    }
  }

  const confirmDeletePipeLine = (pipeline: any) => {
    setPipelineToDelete(pipeline)
    setShowDeleteModal(true)
  }
  return (
    <>
      <div
        className='p-5 rounded h-fit mr10'
        style={{backgroundColor: '#F9F9F9', minWidth: '250px'}}
      >
        <h2 className='text-center mb-4 mt-4'>My Pipelines</h2>
        <ul className='nav flex-column mt-4'>
          {!isLoading && pipelines?.length === 0 && (
            <p className='fw-bold fs-6 text-center'>No pipelines found.</p>
          )}
          {isLoading && (
            <div className='d-flex justify-content-between'>
              <Spinner animation='border' role='status'></Spinner>
            </div>
          )}
          {pipelines?.length > 0 &&
            pipelines.map((pipeline: any, index: number) => (
              <li className='nav-item my-1 cursor-pointer' key={index}>
                <div
                  onClick={() => {
                    navigate(`?pipelineId=${pipeline.uuid}`)
                    if (setShowUserPipeLineModal) setShowUserPipeLineModal(false)
                  }}
                  className={`rounded px-4 py-3 w-100 d-flex flex-row justify-content-between align-items-center ${
                    pipeline.uuid === pipelineId ? 'bg-secondary' : ''
                  }`}
                >
                  <div className='fs-5 fw-bold'>
                    {pipeline.name.length > 15
                      ? pipeline.name.slice(0, 15).concat('...')
                      : pipeline.name}
                  </div>
                  <div>
                    <CustomDropdown
                      elementId={'pipeline'}
                      options={[
                        {
                          label: (
                            <>
                              <i className='fa-solid fs-7 fa-pencil'></i>
                              <span className='mx-2 text-muted'>Edit</span>
                            </>
                          ),
                          handler: () => {
                            setPipelineValues(pipeline)
                            setShowPipeLineModal(true)
                          },
                        },
                        {
                          label: (
                            <>
                              <i className='fa-solid fs-7 text-danger fa-trash'></i>
                              <span className='mx-2 text-danger'>Archive</span>
                            </>
                          ),
                          handler: () => confirmDeletePipeLine(pipeline),
                        },
                      ]}
                    />
                  </div>
                </div>
              </li>
            ))}
        </ul>
      </div>
      {showDeleteModal && (
        <ConfirmationModal
          showModal={showDeleteModal}
          onHideModal={() => {
            setShowDeleteModal(false)
            setPipelineToDelete(null)
          }}
          onCancel={() => {
            setShowDeleteModal(false)
            setPipelineToDelete(null)
          }}
          title='Archive PipeLine'
          subtitle='Are you sure you want to archive this PipeLine?'
          onConfirm={handleDeletePipeLine}
          disableBtns={false}
        />
      )}
    </>
  )
}

export default UserPipelines
