import React, {useEffect, useRef, useState} from 'react'
import {Formik, Form, Field} from 'formik'
import {Button} from 'react-bootstrap'
import EmojiPicker from 'emoji-picker-react'

import {
  useCreatePipelineStageCardComment,
  useUpdatePipelineStageCardComment,
} from '../../../queries/pipelines'
import Image from '../../../modules/common/Image'
import {useAuth} from '../../../modules/auth'
import FileUploader from '../../../modules/common/FileUploader'
import InsertLinkModal from '../../../modules/common/InsertLinkModal'
import Attachments from '../../feeds/components/FeedAttachments'

interface PipelineCardCommentFormProps {
  pipelineId: string
  stageId: string
  cardId: string
  setEditingIndex?: any
  editedComment?: any
  parentComment?: any
  toggleReplyField?: any
}

const PipelineCardCommentForm: React.FC<PipelineCardCommentFormProps> = ({
  pipelineId,
  stageId,
  cardId,
  setEditingIndex,
  editedComment,
  parentComment,
  toggleReplyField,
}) => {
  const initialValues = {comment: ''}

  const [showEmojiPicker, setShowEmojiPicker] = useState(false)
  const [showLinkModal, setShowLinkModal] = useState(false)
  const [showVideoModal, setShowVideoModal] = useState(false)
  const [comments, setComments] = useState<any>(editedComment?.comment || '')
  const [commentAttachments, setCommentAttachments] = useState<any>(
    editedComment?.attachments || []
  )
  const [isUploading, setIsUploading] = useState(false)

  const emojiPickerRef = useRef<HTMLDivElement | null>(null)

  const {mutateAsync: postComment} = useCreatePipelineStageCardComment()
  const {mutateAsync: updateComment} = useUpdatePipelineStageCardComment()
  const {currentUser} = useAuth()

  const onFileUpload = (attachments: any) => {
    setCommentAttachments([...commentAttachments, attachments])
  }

  const handleLinkInsert = (link: string) => {
    setComments((prevComment: any) => prevComment + ' ' + link)
  }

  const toggleEmojiPicker = () => {
    setShowEmojiPicker((prevState: any) => !prevState)
  }

  const onEmojiClick = (emojiObject: any) => {
    setComments((prevInput: any) => prevInput + ' ' + emojiObject.emoji)
  }

  const onVideoInsert = (url: string) => {
    onFileUpload({
      fileType: 'media',
      originalFileName: url,
      signedFileName: url,
    })
  }

  const handleDeleteFile = (index: number) => {
    const updatedAttachments = [...commentAttachments]
    updatedAttachments.splice(index, 1)
    setCommentAttachments(updatedAttachments)
  }

  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (emojiPickerRef.current && !emojiPickerRef.current.contains(event.target as Node)) {
        setShowEmojiPicker(false)
      }
    }

    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])
  return (
    <Formik
      initialValues={initialValues}
      onSubmit={async (_, {resetForm}) => {
        try {
          const payload: any = {
            comment: comments,
            attachments: commentAttachments,
          }
          if (parentComment) {
            payload.parentCommentId = parentComment.id
          }
          if (!editedComment) {
            await postComment({
              pipelineId: pipelineId,
              stageId: stageId,
              cardId: cardId,
              data: {...payload},
            })
          } else {
            await updateComment({
              pipelineId: pipelineId,
              stageId: stageId,
              cardId: cardId,
              commentId: editedComment.id,
              data: {comment: comments, attachments: commentAttachments},
            })
            setEditingIndex(null)
          }
          resetForm()
          setComments('')
          setCommentAttachments([])
          toggleReplyField()
        } catch (error) {
          console.error('Error submitting the form', error)
        }
      }}
    >
      {({values, isSubmitting, submitForm}) => (
        <Form>
          <div className='d-flex my-2 w-100' style={{marginLeft: parentComment ? '60px' : '0px'}}>
            <Image src={`${currentUser?.absoluteProfilePath}`} />
            <div
              className={`bg-light border-custom px-2 py-4 h-auto ms-2 rounded ${
                parentComment ? 'w-85' : 'w-100'
              }`}
            >
              <div className='d-flex align-items-center overflow-hidden max-h70 py-1'>
                <Field
                  name='comment'
                  as='textarea'
                  rows={1}
                  value={values.comment || comments}
                  onChange={(e: any) => {
                    setComments(e.target.value)
                    const textarea = e.target
                    textarea.style.height = 'auto'
                    textarea.style.height = `${textarea.scrollHeight}px`
                  }}
                  onKeyDown={(e: any) => {
                    if (e.key === 'Enter' && !e.shiftKey) {
                      e.preventDefault()
                      if (comments.trim() !== '') {
                        submitForm()
                      }
                    }
                  }}
                  placeholder={`${parentComment?.id ? 'Reply...' : 'Write a comment...'}`}
                  className='outline-none bg-transparent w-75 border-0 resize-none overflow-y-scroll max-h70'
                />
                <div className='feed-comment-icons py-1'>
                  <FileUploader
                    resourceType='comments'
                    setIsUploading={setIsUploading}
                    isUploading={isUploading}
                    onFileUpload={onFileUpload}
                  />
                  <i onClick={toggleEmojiPicker} className='bi bi-emoji-smile fs-2 custom-icon'></i>
                  <i
                    onClick={() => setShowLinkModal(true)}
                    className='bi bi-link fs-2 custom-icon'
                  ></i>
                  <i
                    onClick={() => setShowVideoModal(true)}
                    className='bi bi-youtube fs-2 custom-icon'
                  ></i>
                  {showLinkModal && (
                    <InsertLinkModal
                      handleClose={() => setShowLinkModal(false)}
                      showModal={showLinkModal}
                      onLinkInsert={handleLinkInsert}
                    />
                  )}
                  {showVideoModal && (
                    <InsertLinkModal
                      handleClose={() => setShowVideoModal(false)}
                      showModal={showVideoModal}
                      onLinkInsert={onVideoInsert}
                      type='video'
                    />
                  )}
                  {showEmojiPicker && (
                    <div
                      ref={emojiPickerRef}
                      className='position-absolute z-3'
                      style={{bottom: '150px', right: '27px'}}
                    >
                      <EmojiPicker skinTonesDisabled={true} onEmojiClick={onEmojiClick} />
                    </div>
                  )}
                </div>
              </div>

              {commentAttachments && (
                <div className='d-flex align-items-center overflow-x-scroll'>
                  <Attachments
                    isUploading={isUploading}
                    attachments={commentAttachments}
                    handleDeleteFile={handleDeleteFile}
                  />
                </div>
              )}
            </div>
          </div>
          <div className='d-end'>
            {parentComment?.id && (
              <div className='d-flex w-100 my-3 justify-content-end transition'>
                <Button variant='secondary' className='mx-1' size='sm' onClick={toggleReplyField}>
                  Cancel
                </Button>
                <Button
                  type='submit'
                  variant='primary'
                  className='mx-3'
                  size='sm'
                  disabled={isSubmitting || comments.trim() === ''}
                >
                  {!isSubmitting && 'Reply'}
                  {isSubmitting && (
                    <span className='indicator-progress d-block'>
                      Please wait...
                      <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                    </span>
                  )}
                </Button>
              </div>
            )}
            {editedComment && (
              <Button
                variant='secondary'
                className='me-2'
                size='sm'
                onClick={() => setEditingIndex(null)}
              >
                Cancel
              </Button>
            )}
            {!parentComment?.id && (
              <Button
                variant='primary'
                size='sm'
                type='submit'
                disabled={isSubmitting || comments.trim() === '' || isUploading}
              >
                {isSubmitting ? 'Submitting...' : 'Submit'}
              </Button>
            )}
          </div>
        </Form>
      )}
    </Formik>
  )
}

export default PipelineCardCommentForm
