import FetchingSpinner from '../../../modules/common/FetchingSpinner'
import PipelineCardCommentsRow from './PipelineCardCommentsRow'
import {useGetAllPipelineStageCardComments} from '../../../queries/pipelines'

const PipelineCardComments = ({
  pipelineId,
  stageId,
  cardId,
}: {
  pipelineId: string
  stageId: string
  cardId: string
}) => {
  const {data: commentsResult, isFetching} = useGetAllPipelineStageCardComments(
    pipelineId,
    stageId,
    cardId
  )

  const comments = commentsResult?.comments

  return (
    <div className='mt-5'>
      <h3>Discussion:</h3>
      <div className='comments-section'>
        {comments?.length < 1 && 'Be the first to start discussion!'}
        {isFetching && <FetchingSpinner />}
        {comments?.map((comment: any) => (
          <div key={comment.id}>
            <PipelineCardCommentsRow
              comment={comment}
              pipelineId={pipelineId}
              stageId={stageId}
              cardId={cardId}
            />
            {comment.replies?.map((reply: any) => (
              <PipelineCardCommentsRow
                comment={reply}
                key={reply.id}
                isReply={true}
                pipelineId={pipelineId}
                stageId={stageId}
                cardId={cardId}
              />
            ))}
          </div>
        ))}
      </div>
    </div>
  )
}

export default PipelineCardComments
