import {FC, Suspense, useEffect, useState} from 'react'
import {Navigate, Route, Routes, useLocation, useNavigate} from 'react-router-dom'
import {MasterLayout} from '../layout/MasterLayout'
import TopBarProgress from 'react-topbar-progress-indicator'
import {getCSSVariableValue} from '../../_metronic/assets/ts/_utils'
import {WithChildren} from '../../_metronic/helpers'
import {CommunityIndex} from '../pages/community/CommunityIndex'
import {MemberIndex} from '../pages/members/MemberIndex'
import {ProjectIndex} from '../pages/projects/ProjectIndex'
import {MessagesIndex} from '../pages/messages/MessagesIndex'
import LeaderBoardIndex from '../pages/leaderBoard/LeaderBoardIndex'
import {ViewProfile} from '../pages/profile/viewProfile/ViewProfile'
import {useAuth} from '../modules/auth'
import ThreadsIndex from '../pages/threads/ThreadsIndex'
import EventsIndex from '../pages/events/EventsIndex'
import FeedsIndex from '../pages/feeds/FeedsIndex'
import {SettingsIndex} from '../pages/settings/SettingsIndex'
import NotesIndex from '../pages/notes/NotesIndex'
import GlobalSearchIndex from '../pages/globalSearch/GlobalSearchIndex'
import GovChatIndex from '../pages/govchat/GovChatIndex'
import CoursesApp from '../pages/courses/CoursesApp'
import ResourcesIndex from '../pages/resources/ResourcesIndex'
import CommunitySettingsIndex from '../pages/communitySettings/CommunitySettingsIndex'
import PipeLinesIndex from '../pages/pipelines/PipeLinesIndex'
import {isProfileIncomplete} from '../modules/common/utils'
import CrmIndex from '../pages/CRM/CrmIndex'

const PrivateRoutes = () => {
  const {currentUser, selectedCommunity} = useAuth()
  const isPremiumUser = selectedCommunity?.isPremiumUser
  const navigate = useNavigate()
  const location = useLocation()
  const [path] = useState(location.pathname)

  useEffect(() => {
    const isProfileIncompleted = path !== '/settings' && isProfileIncomplete(currentUser)

    if (isProfileIncompleted) {
      navigate('/settings')
    }
  }, [path, navigate, currentUser])

  return (
    <Routes>
      <Route element={<MasterLayout />}>
        <Route path='auth/*' element={<Navigate to='/feeds/public' />} />
        <Route path='dashboard' element={<FeedsIndex />} />

        <Route
          path='view-profile/:id'
          element={
            <SuspensedView>
              <ViewProfile />
            </SuspensedView>
          }
        />
        <Route
          path='settings'
          element={
            <SuspensedView>
              <SettingsIndex />
            </SuspensedView>
          }
        />

        <Route
          path='community'
          element={
            <SuspensedView>
              <CommunityIndex />
            </SuspensedView>
          }
        />

        {selectedCommunity?.isCommunityAdmin && (
          <Route
            path='community-settings'
            element={
              <SuspensedView>
                <CommunitySettingsIndex />
              </SuspensedView>
            }
          />
        )}

        <Route
          path='feeds/:feedType'
          element={
            <SuspensedView>
              <FeedsIndex />
            </SuspensedView>
          }
        />
        <Route
          path='courses/*'
          element={
            <SuspensedView>
              <CoursesApp />
            </SuspensedView>
          }
        />

        {isPremiumUser && (
          <>
            <Route
              path='govchat'
              element={
                <SuspensedView>
                  <GovChatIndex />
                </SuspensedView>
              }
            />

            <Route
              path='govchat/:chatId'
              element={
                <SuspensedView>
                  <GovChatIndex />
                </SuspensedView>
              }
            />

            <Route
              path='support'
              element={
                <SuspensedView>
                  <ThreadsIndex />
                </SuspensedView>
              }
            />
          </>
        )}

        <Route
          path='member-area'
          element={
            <SuspensedView>
              <MemberIndex />
            </SuspensedView>
          }
        />

        <Route
          path='project-area'
          element={
            <SuspensedView>
              <ProjectIndex />
            </SuspensedView>
          }
        />

        <Route
          path='resources'
          element={
            <SuspensedView>
              <ResourcesIndex />
            </SuspensedView>
          }
        />

        <Route
          path='messages/:id'
          element={
            <SuspensedView>
              <MessagesIndex />
            </SuspensedView>
          }
        />

        <Route
          path='leaderboard'
          element={
            <SuspensedView>
              <LeaderBoardIndex />
            </SuspensedView>
          }
        />

        <Route
          path='calendar'
          element={
            <SuspensedView>
              <EventsIndex />
            </SuspensedView>
          }
        />

        <Route
          path='search'
          element={
            <SuspensedView>
              <GlobalSearchIndex />
            </SuspensedView>
          }
        />
        <Route
          path='pipeline'
          element={
            <SuspensedView>
              <PipeLinesIndex />
            </SuspensedView>
          }
        />

        <Route
          path='coming-soon'
          element={
            <SuspensedView>
              <CrmIndex />
            </SuspensedView>
          }
        />

        <Route path='notes' element={<NotesIndex />} />
        <Route path='*' element={<Navigate to='/error/404' />} />
      </Route>
    </Routes>
  )
}

const SuspensedView: FC<WithChildren> = ({children}) => {
  const baseColor = getCSSVariableValue('--bs-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export {PrivateRoutes}
