import {Card} from 'react-bootstrap'
import {useState} from 'react'
import moment from 'moment'

import PipeLineCardDetail from './PipeLineCardDetail'

const PipeLineCard = ({
  card,
  pipelineId,
  stageId,
}: {
  card: any
  pipelineId: string
  stageId: string
}) => {
  const [showPipeLineDetailModal, setShowPipeLineDetailModal] = useState(false)
  console.log('card', card)
  return (
    <Card className='stage-card cursor-pointer' style={{backgroundColor: '#f4f4f4'}}>
      <Card.Body className='px-5 py-4' onClick={() => setShowPipeLineDetailModal(true)}>
        {card?.labels && (
          <div className='d-flex'>
            {card.labels?.length > 0 && (
              <div className='d-flex flex-wrap w-fit mb-3'>
                {card.labels.map((label: any, index: number) => (
                  <div
                    key={index}
                    className='rounded my-1'
                    style={{
                      height: '8px',
                      width: '50px',
                      margin: '0 1px',
                      backgroundColor: label.value,
                    }}
                  ></div>
                ))}
              </div>
            )}
          </div>
        )}
        <Card.Title>{card.name}</Card.Title>
        <div className='d-flex align-items-center'>
          {(card.startDate || card.dueDate) && (
            <div>
              <p
                className={`mb-2 fw-medium fs-6 badge text-light cursor-pointer ${
                  card.dueDate &&
                  moment(card.dueDate).endOf('day').isBefore(moment()) &&
                  card.status === 'inProgress'
                    ? 'text-bg-danger'
                    : card.status === 'completed'
                    ? 'text-bg-success'
                    : 'text-bg-primary'
                }`}
                title={
                  card.status === 'completed'
                    ? 'Completed'
                    : card.dueDate &&
                      moment(card.dueDate).endOf('day').isBefore(moment()) &&
                      card.status === 'inProgress'
                    ? 'Overdue'
                    : 'Due date'
                }
              >
                <span className='fa-solid fa-clock me-1'></span>
                <span>
                  {card.startDate ? moment(card.startDate).format('DD MMM') : ''}
                  {card.startDate && card.dueDate && <span className='mx-1'>-</span>}
                  {card.dueDate && moment(card.dueDate).format('DD MMM')}
                </span>
              </p>
            </div>
          )}
          {card.description?.length > 0 && (
            <i
              className={`fa-solid fa-align-left fs-6 text-black mb-2 ${
                (card.startDate || card.dueDate) && 'ms-2'
              }`}
              title='This card has a description'
            ></i>
          )}
          {card.attachments?.length > 0 && (
            <i
              className={`bi bi-paperclip fs-6 text-black mb-2 ${card.description && 'ms-1'}`}
              title='This card has attachments'
            ></i>
          )}
        </div>
      </Card.Body>

      {showPipeLineDetailModal && (
        <PipeLineCardDetail
          showPipeLineDetailModal={showPipeLineDetailModal}
          setShowPipeLineDetailModal={setShowPipeLineDetailModal}
          card={card}
          pipelineId={pipelineId}
          stageId={stageId}
        />
      )}
    </Card>
  )
}

export default PipeLineCard
