import {useState} from 'react'
import {Button} from 'react-bootstrap'

import PipeLineStages from './components/PipeLineStages'
import GenericModal from '../../modules/common/Modal'
import PipeLineForm from './components/PipeLineForm'
import UserPipelines from './components/UserPipelines'
import GenericOffcanvas from '../../modules/common/Offcanvas'
import {KTIcon} from '../../../_metronic/helpers'

import './PipeLines.scss'

function PipeLinesIndex() {
  const [showPipeLineModal, setShowPipeLineModal] = useState(false)
  const [showUserPipeLineModal, setShowUserPipeLineModal] = useState(false)
  const [pipelineValues, setPipelineValues] = useState<any>(null)

  return (
    <div className='d-flex' id='kt_pipeline_details_view'>
      <div className='d-block lg-sidebar'>
        <UserPipelines
          setShowPipeLineModal={setShowPipeLineModal}
          setPipelineValues={setPipelineValues}
        />
      </div>
      <div className='d-none sm-pipelines-sidebar'>
        <div className='d-flex justify-content-between'>
          <Button variant='transparent' size='sm' onClick={() => setShowUserPipeLineModal(true)}>
            <KTIcon iconName='entrance-left' className='fs-1' />
          </Button>
          <Button
            variant='primary'
            className='d-flex flex-row align-items-center mr15'
            onClick={() => setShowPipeLineModal(true)}
          >
            <i className='fa-solid fa-add'></i>
            <span className='mx-2'>Pipeline</span>
          </Button>
        </div>
        <GenericOffcanvas
          showOffcanvas={showUserPipeLineModal}
          onHideOffcanvas={() => setShowUserPipeLineModal(false)}
          children={
            <UserPipelines
              setShowPipeLineModal={setShowPipeLineModal}
              setShowUserPipeLineModal={setShowUserPipeLineModal}
              setPipelineValues={setPipelineValues}
            />
          }
        />
      </div>
      <div className='d-flex flex-column w-75 pipeline-stages-container p-5'>
        <div className='d-end lg-sidebar'>
          <Button
            variant='primary'
            className='d-flex flex-row align-items-center'
            onClick={() => setShowPipeLineModal(true)}
          >
            <i className='fa-solid fa-add'></i>
            <span className='mx-2'>Pipeline</span>
          </Button>
        </div>
        <div className='mt-3'>
          <PipeLineStages />
        </div>
      </div>
      <div>
        {showPipeLineModal && (
          <GenericModal
            title={pipelineValues ? 'Edit Pipeline' : 'Add Pipeline'}
            showModal={showPipeLineModal}
            onHideModal={() => {
              setShowPipeLineModal(false)
            }}
            hideFooter={true}
            modalProps={{
              size: 'md',
              centered: true,
            }}
          >
            <PipeLineForm
              setShowPipeLineModal={setShowPipeLineModal}
              pipelineValues={pipelineValues}
              setPipelineValues={setPipelineValues}
            />
          </GenericModal>
        )}
      </div>
    </div>
  )
}

export default PipeLinesIndex
