import {useEffect, useState} from 'react'
import {Modal} from 'react-bootstrap'
import {useNavigate, useSearchParams} from 'react-router-dom'

import {useAuth} from '../../modules/auth'
import FeedForm from './components/FeedForm'
import FeedIndexCard from './components/FeedIndexCard'
import {Pagination} from '../../hooks/Pagination'
import FetchingSpinner from '../../modules/common/FetchingSpinner'
import CategoriesFilterCard from './components/CatogriesFilterCard'
import HighlightFeeds from './components/HiglightFeeds'
import {useGetFeeds} from '../../queries/feeds'
import {useGetCategories} from '../../queries/categories'
import Image from '../../modules/common/Image'

import './feeds.css'
import LeaderBoardCard from '../leaderBoard/Components/LeaderBoardCard'

const FeedsIndex = ({userId}: {userId?: any}) => {
  const [searchParams] = useSearchParams()
  const [showCreateFeedModal, setShowCreateFeedModal] = useState(false)
  const [selectedCategoryId, setSelectedCategoryId] = useState(searchParams.get('categoryId') || '')
  const page = searchParams.get('page') || 1
  const navigate = useNavigate()
  const {currentUser} = useAuth()
  const feedsResult = useGetFeeds({page, categoryId: selectedCategoryId, userId})
  const categoriesResult = useGetCategories()

  const feedsData = feedsResult?.data
  const categories = categoriesResult?.data?.categories

  const handlePage = (page: number) => {
    // Get the current search params from the URL
    const searchParams: any = new URLSearchParams(window.location.search)

    // Create an object to hold the current params
    const currentParams: {[key: string]: string} = {}
    for (const [key, value] of searchParams.entries()) {
      currentParams[key] = value
    }

    // Add the new 'page' param
    currentParams['page'] = page.toString()

    // If 'selectedCategoryId' is set, add it to the params
    if (selectedCategoryId) {
      currentParams['categoryId'] = selectedCategoryId.toString()
    }

    // Construct the new query string
    const newQueryString = new URLSearchParams(currentParams).toString()

    // Navigate to the new URL with updated params
    navigate(`?${newQueryString}`)
  }

  useEffect(() => {
    window.scrollTo({top: 0})
  }, [page])

  return (
    <>
      <div className={`kt-content-main w-75 ${userId ? 'm-0' : ''}`}>
        {feedsData && (
          <div className='feeds-container'>
            {(!userId || userId === currentUser?.id) && (
              <div className='d-flex justify-content-center'>
                <div
                  style={{border: '1px solid #cdcdcd'}}
                  className='bg-light feeds-card d-flex align-items-center shadow px-4 py-3 mb-3 w-100 rounded cursor-pointer'
                  onClick={() => setShowCreateFeedModal(true)}
                >
                  <Image src={`${currentUser?.absoluteProfilePath}`} />
                  <div className='border-0 mx-5 mt-1 w-100'>
                    <h2 style={{color: '#909090'}}>Write something...</h2>
                  </div>
                </div>
              </div>
            )}

            <div>
              {feedsData?.feeds.length === 0 && <em className='px-3 fw-bold'>No feeds found</em>}
              {feedsData?.feeds.map((feed: any) => (
                <FeedIndexCard feed={feed} key={feed.id} currentUserId={currentUser?.id} />
              ))}
            </div>

            <div className='d-flex mt-3 justify-content-center align-items-center'>
              {feedsData && feedsData.totalPages > 1 && (
                <Pagination
                  page={Number(page)}
                  totalPages={feedsData.totalPages}
                  handlePagination={handlePage}
                />
              )}
            </div>
            <Modal
              size='lg'
              aria-labelledby='contained-modal-title-vcenter'
              centered
              show={showCreateFeedModal}
              onHide={() => setShowCreateFeedModal(false)}
              enforceFocus={false}
              backdrop='static'
            >
              <Modal.Header
                style={{paddingTop: '10px', paddingBottom: '0px'}}
                className='m-0 px-5 border-bottom-0'
              >
                <div className='d-flex'>
                  <Image
                    src={`${currentUser?.absoluteProfilePath}`}
                    className='rounded-circle object-top object-cover w40 h40'
                  />
                  <div className='d-block mx-2'>
                    <h3 className='m-0'>
                      {currentUser?.firstName} {currentUser?.lastName}
                    </h3>
                    <p className='text-gray m-0'>{'posting feed...'}</p>
                  </div>
                </div>
              </Modal.Header>
              <Modal.Body className='px-5 py-3'>
                <FeedForm setShowCreateFeedModal={setShowCreateFeedModal} />
              </Modal.Body>
            </Modal>
          </div>
        )}
        {!feedsData && <FetchingSpinner />}
      </div>
      <div className='kt-content-right'>
        <div className={`${userId ? '' : 'kt-content-right_fixed w-100'}`}>
          {!userId && (
            <CategoriesFilterCard
              categories={categories}
              selectedCategoryId={selectedCategoryId}
              setSelectedCategoryId={setSelectedCategoryId}
            />
          )}
          {!userId && (
            <div>
              <HighlightFeeds />
            </div>
          )}
          {!userId && (
            <div style={{marginBottom: '100px'}}>
              <LeaderBoardCard />
            </div>
          )}
        </div>
      </div>
    </>
  )
}

export default FeedsIndex
