import {useMutation, useQuery, useQueryClient} from '@tanstack/react-query'
import {toast} from 'react-toastify'

import {PIPELINES_COMMENTS_QUERY_KEY, PIPELINES_QUERY_KEY} from '../constants/reactQueryKeys'
import * as pipelinesApi from '../apis/pipelines'
import {parseError} from '../modules/common/utils'

// pipelines
export const useGetAllPipelines = () => {
  return useQuery({
    queryKey: [PIPELINES_QUERY_KEY],
    queryFn: () => pipelinesApi.getAllPipelines(),
  })
}

export const useGetPipelineById = (pipelineId: string) => {
  return useQuery({
    queryKey: [PIPELINES_QUERY_KEY, pipelineId],
    queryFn: () => pipelinesApi.getPipelineById(pipelineId),
    enabled: !!pipelineId,
  })
}

export const useCreatePipeline = () => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: (data: any) => pipelinesApi.createPipeline(data),
    onSuccess: () => {
      queryClient.invalidateQueries({queryKey: [PIPELINES_QUERY_KEY]})
    },
    onError: (error: any) => {
      toast.error(parseError(error))
    },
  })
}

export const useUpdatePipeline = () => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: ({pipelineId, data}: {pipelineId: string; data: any}) =>
      pipelinesApi.updatePipeline(pipelineId, data),
    onSuccess: () => {
      queryClient.invalidateQueries({queryKey: [PIPELINES_QUERY_KEY]})
    },
    onError: (error: any) => {
      toast.error(parseError(error))
    },
  })
}

export const useDeletePipeline = () => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: (pipelineId: string) => pipelinesApi.deletePipeline(pipelineId),
    onSuccess: () => {
      queryClient.invalidateQueries({queryKey: [PIPELINES_QUERY_KEY]})
    },
    onError: (error: any) => {
      toast.error(parseError(error))
    },
  })
}

// pipeline stages
export const useGetPipelineStagesByPipeline = (pipelineId: string) => {
  return useQuery({
    queryKey: [PIPELINES_QUERY_KEY, pipelineId],
    queryFn: () => pipelinesApi.getPipelineStagesByPipeline(pipelineId),
    enabled: !!pipelineId,
  })
}

export const useCreatePipelineStage = () => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: ({pipelineId, data}: {pipelineId: string; data: any}) =>
      pipelinesApi.createPipelineStage(pipelineId, data),
    onSuccess: () => {
      queryClient.invalidateQueries({queryKey: [PIPELINES_QUERY_KEY]})
    },
    onError: (error: any) => {
      toast.error(parseError(error))
    },
  })
}

export const useUpdatePipelineStage = () => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: ({pipelineId, stageId, data}: {pipelineId: string; stageId: string; data: any}) =>
      pipelinesApi.updatePipelineStage(pipelineId, stageId, data),
    onSuccess: () => {
      queryClient.invalidateQueries({queryKey: [PIPELINES_QUERY_KEY]})
    },
    onError: (error: any) => {
      toast.error(parseError(error))
    },
  })
}

export const useUpdatePipelineStageOrder = () => {
  return useMutation({
    mutationFn: ({
      pipelineId,
      stageId,
      order,
    }: {
      pipelineId: string
      stageId: string
      order: number
    }) => pipelinesApi.updatePipelineStageOrder(pipelineId, stageId, order),
    onError: (error: any) => {
      toast.error(parseError(error))
    },
  })
}

export const useDeletePipelineStage = () => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: ({pipelineId, stageId}: {pipelineId: string; stageId: string}) =>
      pipelinesApi.deletePipelineStage(pipelineId, stageId),
    onSuccess: () => {
      queryClient.invalidateQueries({queryKey: [PIPELINES_QUERY_KEY]})
    },
    onError: (error: any) => {
      toast.error(parseError(error))
    },
  })
}

// pipeline stage cards
export const useGetPipelineStageCards = (pipelineId: string, stageId: string) => {
  return useQuery({
    queryKey: [PIPELINES_QUERY_KEY, pipelineId, stageId],
    queryFn: () => pipelinesApi.getPipelineStageCards(pipelineId, stageId),
    enabled: !!pipelineId && !!stageId,
  })
}

export const useCreatePipelineStageCard = () => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: ({pipelineId, stageId, data}: {pipelineId: string; stageId: string; data: any}) =>
      pipelinesApi.createPipelineStageCard(pipelineId, stageId, data),
    onSuccess: () => {
      queryClient.invalidateQueries({queryKey: [PIPELINES_QUERY_KEY]})
    },
    onError: (error: any) => {
      toast.error(parseError(error))
    },
  })
}

export const useUpdatePipelineStageCard = () => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: ({
      pipelineId,
      stageId,
      cardId,
      data,
    }: {
      pipelineId: string
      stageId: string
      cardId: string
      data: any
    }) => pipelinesApi.updatePipelineStageCard(pipelineId, stageId, cardId, data),
    onSuccess: () => {
      queryClient.invalidateQueries({queryKey: [PIPELINES_QUERY_KEY]})
    },
    onError: (error: any) => {
      toast.error(parseError(error))
    },
  })
}

export const useUpdatePipelineStageCardOrder = () => {
  return useMutation({
    mutationFn: ({
      pipelineId,
      stageId,
      cardId,
      order,
      newStageId,
    }: {
      pipelineId: string
      stageId: string
      cardId: string
      order: number
      newStageId?: string
    }) =>
      pipelinesApi.updatePipelineStageCardOrder(pipelineId, stageId, cardId, order, {newStageId}),
    onError: (error: any) => {
      toast.error(parseError(error))
    },
  })
}

export const useDeletePipelineStageCard = () => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: ({
      pipelineId,
      stageId,
      cardId,
    }: {
      pipelineId: string
      stageId: string
      cardId: string
    }) => pipelinesApi.deletePipelineStageCard(pipelineId, stageId, cardId),
    onSuccess: () => {
      queryClient.invalidateQueries({queryKey: [PIPELINES_QUERY_KEY]})
    },
    onError: (error: any) => {
      toast.error(parseError(error))
    },
  })
}

export const useGetAllPipelineStageCardComments = (
  pipelineId: string,
  stageId: string,
  cardId: string,
  page: number = 1,
  limit: number = 1000
) => {
  return useQuery({
    queryKey: [PIPELINES_COMMENTS_QUERY_KEY],
    queryFn: () =>
      pipelinesApi.getPipelineStageCardComments(pipelineId, stageId, cardId, page, (limit = 1000)),
  })
}

export const useCreatePipelineStageCardComment = () => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: ({
      pipelineId,
      stageId,
      cardId,
      data,
    }: {
      pipelineId: string
      stageId: string
      cardId: string
      data: any
    }) => pipelinesApi.createPipelineStageCardComment(pipelineId, stageId, cardId, data),
    onSuccess: () => {
      queryClient.invalidateQueries({queryKey: [PIPELINES_COMMENTS_QUERY_KEY]})
    },
    onError: (error: any) => {
      toast.error(parseError(error))
    },
  })
}

export const useUpdatePipelineStageCardComment = () => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: ({
      pipelineId,
      stageId,
      cardId,
      commentId,
      data,
    }: {
      pipelineId: string
      stageId: string
      cardId: string
      commentId: string
      data: any
    }) => pipelinesApi.updatePipelineStageCardComment(pipelineId, stageId, cardId, commentId, data),
    onSuccess: () => {
      queryClient.invalidateQueries({queryKey: [PIPELINES_COMMENTS_QUERY_KEY]})
    },
    onError: (error: any) => {
      toast.error(parseError(error))
    },
  })
}

export const useDeletePipelineStageCardComment = () => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: ({
      pipelineId,
      stageId,
      cardId,
      commentId,
    }: {
      pipelineId: string
      stageId: string
      cardId: string
      commentId: string
    }) => pipelinesApi.deletePipelineStageCardComment(pipelineId, stageId, cardId, commentId),
    onSuccess: () => {
      queryClient.invalidateQueries({queryKey: [PIPELINES_COMMENTS_QUERY_KEY]})
    },
    onError: (error: any) => {
      toast.error(parseError(error))
    },
  })
}
