import {useAuth} from '../../../modules/auth'
import {AsideMenuItem} from './AsideMenuItem'

export function AsideMenuMain() {
  const {selectedCommunity} = useAuth()
  const isPremiumUser = selectedCommunity?.isPremiumUser

  return (
    <>
      <AsideMenuItem to='/feeds/public' title='Community' svgIcon='/media/svg/general/users.svg' />
      <AsideMenuItem to='/calendar' title='Calendar' svgIcon='/media/svg/general/calendar.svg' />
      <AsideMenuItem
        to='/community'
        title='Member Directory'
        svgIcon='/media/svg/general/address-book.svg'
      />
      {isPremiumUser && (
        <>
          <AsideMenuItem to='/pipeline' title='Pipelines' svgIcon='/media/svg/general/chart.svg' />
          <AsideMenuItem to='/courses' title='Courses' svgIcon='/media/svg/general/course.svg' />
          <AsideMenuItem to='/govchat' title='Govchat' svgIcon='/media/svg/general/chat.svg' />
          <AsideMenuItem
            to='/support'
            title='Support'
            svgIcon='/media/svg/general/information.svg'
          />
        </>
      )}
      <AsideMenuItem
        to='/resources'
        title='Resources'
        svgIcon='/media/svg/general/scan-barcode.svg'
      />
      <AsideMenuItem
        to='/leaderboard'
        title='Leaderboard'
        svgIcon='/media/svg/general/barcode.svg'
      />
      <AsideMenuItem to='/notes' title='Notes' svgIcon='/media/svg/general/medal-star.svg' />
    </>
  )
}
