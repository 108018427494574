import {Button, Form, Modal} from 'react-bootstrap'
import {Formik, Field, Form as FormikForm, ErrorMessage, FieldArray} from 'formik'
import * as Yup from 'yup'
import {useNavigate} from 'react-router-dom'

import {useCreatePipeline, useUpdatePipeline} from '../../../queries/pipelines'

const validationSchema = Yup.object().shape({
  name: Yup.string().required('Pipeline Name is required'),
  stages: Yup.array().of(Yup.string().optional()),
})

function PipeLineForm({
  setShowPipeLineModal,
  pipelineValues,
  setPipelineValues,
}: {
  setShowPipeLineModal: (show: boolean) => void
  pipelineValues: any
  setPipelineValues: (pipelineValues: any) => void
}) {
  const initialValues = {
    name: '',
    stages: [''],
  }

  const navigate = useNavigate()

  const {mutateAsync: createPipeline, isPending} = useCreatePipeline()
  const {mutateAsync: updatePipeline, isPending: isUpdating} = useUpdatePipeline()

  const handleClosePipelineModal = () => {
    setShowPipeLineModal(false)
    setPipelineValues(null)
  }

  return (
    <div>
      <Formik
        initialValues={
          !pipelineValues
            ? initialValues
            : {
                name: pipelineValues.name,
              }
        }
        validationSchema={
          !pipelineValues
            ? validationSchema
            : Yup.object().shape({
                name: Yup.string().required('Pipeline Name is required'),
              })
        }
        onSubmit={async (values, {resetForm}) => {
          try {
            const payload: any = {...values}

            // Remove stages from payload if it's empty or contains only an empty string
            if (payload.stages && payload.stages.length === 1 && !payload.stages[0]) {
              delete payload.stages
            }
            if (!pipelineValues) {
              const response = await createPipeline(payload)
              navigate(`?pipelineId=${response?.uuid}`)
            } else {
              await updatePipeline({pipelineId: pipelineValues.uuid, data: values})
            }
            handleClosePipelineModal()
            resetForm()
          } catch (error) {
            console.error(error)
          } finally {
            setPipelineValues(null)
          }
        }}
      >
        {({values}: any) => (
          <FormikForm>
            <div>
              <h4 className='required'>Pipeline Name</h4>
              <Form.Group controlId='validationFormik101' className='mt-3'>
                <Field type='text' name='name' placeholder='Name....' className='form-control' />
                <ErrorMessage name='name' component='div' className='text-danger' />
              </Form.Group>
            </div>
            {!pipelineValues && (
              <div className='mb-3 mt-6'>
                <h5>Add Stages</h5>
                <FieldArray name='stages'>
                  {({push, remove}) => (
                    <>
                      {values.stages.map((_: any, index: number) => (
                        <div key={index} className='mb-3'>
                          <Form.Group className='position-relative w-100'>
                            <Field
                              type='text'
                              name={`stages[${index}]`}
                              placeholder='Stage Name....'
                              className='form-control'
                            />
                            <div className='d-flex align-items-start justify-content-end mt-2'>
                              {index === values.stages.length - 1 && (
                                <Button
                                  onClick={() => push('')}
                                  style={{padding: '8px'}}
                                  size='sm'
                                  className='btn btn-primary mt-3 mx-1'
                                >
                                  <i className='fa-solid fs-3 p-0 fa-plus'></i>
                                </Button>
                              )}
                              {values.stages.length > 1 && (
                                <Button
                                  onClick={() => remove(index)}
                                  style={{padding: '8px'}}
                                  size='sm'
                                  className='btn btn-danger mt-3 mx-1'
                                >
                                  <i className='fa-solid fs-3 p-0 fa-minus'></i>
                                </Button>
                              )}
                            </div>
                          </Form.Group>
                        </div>
                      ))}
                    </>
                  )}
                </FieldArray>
              </div>
            )}
            <Modal.Footer className='px-1 py-4'>
              <Button
                variant='secondary'
                onClick={handleClosePipelineModal}
                disabled={isPending || isUpdating}
              >
                Close
              </Button>
              <Button variant='primary' type='submit' disabled={isPending || isUpdating}>
                Save
              </Button>
            </Modal.Footer>
          </FormikForm>
        )}
      </Formik>
    </div>
  )
}

export default PipeLineForm
