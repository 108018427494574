import {useEffect, useMemo, useState} from 'react'
import {Draggable, Droppable} from 'react-beautiful-dnd'

import CustomDropdown from '../../../modules/common/CustomDropDown'
import PipeLineCardForm from './PipeLineCardForm'
import PipeLineCard from './PipeLineCard'
import {ConfirmationModal} from '../../../modules/common/ConfirmationModal'
import {useDeletePipelineStage} from '../../../queries/pipelines'
import PipeLineCardFormLite from './PipeLineCardFormLite'
import PipeLineStageForm from './PipeLineStageForm'

interface PipelineStageProps {
  stage: any
  pipelineId: string
}

const PipeLineStage: React.FC<PipelineStageProps> = ({stage, pipelineId}) => {
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [showCardModal, setShowCardModal] = useState(false)
  const [showEditStageModal, setShowEditStageModal] = useState(false)
  const [stageCards, setStageCards] = useState([])
  const {mutateAsync: deletePipelineStage} = useDeletePipelineStage()

  const editStageOptions = useMemo(
    () => [
      {
        label: (
          <>
            <span className='fa-solid fs-7 fa-pencil text-muted'></span>
            <span className='mx-2 text-muted'>Edit</span>
          </>
        ),
        handler: () => setShowEditStageModal(true),
      },
      {
        label: (
          <>
            <span className='fa-solid fs-7 text-danger fa-trash'></span>
            <span className='mx-2 text-danger'>Archive</span>
          </>
        ),
        handler: () => setShowDeleteModal(true),
      },
    ],
    [stage]
  )

  const handleDeletePipeLine = async () => {
    await deletePipelineStage({pipelineId: pipelineId, stageId: stage.uuid})
    setShowDeleteModal(false)
  }

  useEffect(() => {
    setStageCards(stage.pipelineStageCards || [])
  }, [stage.pipelineStageCards])

  return (
    <div className='card-container bg-white cursor-default'>
      <div className='d-flex flex-row rounded justify-content-between px-4 py-3 min-w250 bg-primary'>
        <h4 className='text-white m-0'>{stage.name}</h4>
        <div>
          <CustomDropdown
            elementId={'pipeline'}
            options={editStageOptions}
            className='stage-dropdown'
          />
        </div>
      </div>
      <Droppable key={stage.uuid} droppableId={stage.uuid} type='card'>
        {(provided) => (
          <div ref={provided.innerRef} {...provided.droppableProps} className='card-list'>
            {stageCards.map((card: any, cardIndex: number) => (
              <Draggable key={card.uuid} draggableId={card.uuid} index={cardIndex}>
                {(provided) => (
                  <div
                    className='mt-4'
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                  >
                    <PipeLineCard card={card} pipelineId={pipelineId} stageId={stage.uuid} />
                  </div>
                )}
              </Draggable>
            ))}
            {provided.placeholder}

            <PipeLineCardFormLite pipelineId={pipelineId} stageId={stage.uuid} />
          </div>
        )}
      </Droppable>

      <div>
        {showCardModal && (
          <PipeLineCardForm
            pipelineId={pipelineId}
            stageId={stage.uuid}
            setShowCardModal={setShowCardModal}
            showCardModal={showCardModal}
          />
        )}
        {showEditStageModal && (
          <PipeLineStageForm
            stageValues={stage}
            pipelineId={pipelineId}
            showStageFormModal={showEditStageModal}
            setShowStageFormModal={setShowEditStageModal}
          />
        )}
        {showDeleteModal && (
          <ConfirmationModal
            showModal={showDeleteModal}
            onHideModal={() => setShowDeleteModal(false)}
            onCancel={() => setShowDeleteModal(false)}
            title='Archive Stage'
            subtitle='Are you sure you want to archive this Stage?'
            onConfirm={handleDeletePipeLine}
            disableBtns={false}
          />
        )}
      </div>
    </div>
  )
}

export default PipeLineStage
